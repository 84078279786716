import { useState } from 'react';
import { useCartChange, useMachineCartChange } from 'ui';

const data = (window as any).app.preloadState.miniCart;

function App() {
    const [count, setCount] = useState(data.cartItemsCount);

    if (data.machineSales) {
        useMachineCartChange(cart => setCount(cart.lineItems.length));
    } else {
        useCartChange(cart => {
            setCount(cart.kits.map(kit => kit.kitLineItems.length).reduce(

                (sum, next) => sum + next, cart.lineItems.filter(item => !item.isRemanItem).length
            ))
        });
    }

    if (count > 0) {
        return <>{count}</>;
    } else return <></>;
}

export default App;
